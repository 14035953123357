import React, { useState } from "react";
import "./DropdownSelect.css";

// Material UI
import { Button, Menu, MenuItem } from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";

function DropdownSelect({
  dropdown_array,
  value_name,
  value,
  setSelectedOption,
}) {
  const [dropdownOptionElement, setDropdownOptionElement] = useState(null); // Dropdown HTML elemnent code
  const [
    selectedDropdownOptionIndex,
    setSelectedDropdownOptionIndex,
  ] = useState(value);

  const handleShowDropdownOptions = (e) => {
    setDropdownOptionElement(e.currentTarget);
  };

  const handleSetDropdownOption = (e, index) => {
    setSelectedDropdownOptionIndex(index);
    setSelectedOption(dropdown_array[index]);
    setDropdownOptionElement(null);
  };

  const handleCloseDropdownOptions = () => {
    setDropdownOptionElement();
  };

  return (
    <div className="dropdownSelect">
      <Button
        aria-controls="dropdown-menu"
        aria-haspopup="true"
        onClick={handleShowDropdownOptions}
      >
        {value_name}: {dropdown_array[selectedDropdownOptionIndex]}
        <ArrowDropDown />
      </Button>

      <Menu
        id="dropdown-menu"
        anchorEl={dropdownOptionElement}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={Boolean(dropdownOptionElement)}
        onClose={handleCloseDropdownOptions}
      >
        {dropdown_array.map((option, index) => (
          <MenuItem
            key={option}
            selected={index === selectedDropdownOptionIndex}
            onClick={(e) => handleSetDropdownOption(e, index)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default DropdownSelect;
