import React, { useEffect, useState } from "react";
import "./Body.css";
// Material Ui
import DropdownSelect from "../common/DropdownSelect";
// Components
import Sidebar from "./Sidebar";
import TodoList from "./TodoList";
import AddTodo from "./AddTodo";
// Context API
import { useStateValue } from "../../context/StateProvider";

// TODO: Sort Option -> Pull from DB later
const sortOptions = ["Title", "Date", "Status", "Priority", "Label"];

const todoItemSearchCheck = (todoItem, searchValue) =>
  todoItem.title.toLowerCase().includes(searchValue.toLowerCase()) ||
  todoItem.detail.toLowerCase().includes(searchValue.toLowerCase());

function Body() {
  const [
    { sort_option, todos, filter_priority_option, filtered_todos },
    dispatch,
  ] = useStateValue();
  const [searchValue, setSearchValue] = useState("");
  const [searchFilteredTodos, setSearchFilteredTodos] = useState(todos);

  const setSortOption = (sortOption) => {
    dispatch({
      type: "CHANGE_SORT_OPTION",
      sort_option:
        sortOption !== sort_option.name
          ? { name: sortOption, asc: true }
          : { name: sortOption, asc: !sort_option.asc },
    });
  };

  useEffect(() => {
    if (searchValue) {
      filterSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, filter_priority_option]);

  const filterSearch = () => {
    setSearchFilteredTodos(
      filter_priority_option === "All"
        ? todos.filter((todoItem) => todoItemSearchCheck(todoItem, searchValue))
        : filtered_todos.filter((todoItem) =>
            todoItemSearchCheck(todoItem, searchValue)
          )
    );
  };

  return (
    <div className="body">
      {/* TodoList */}
      <div className="body__left">
        <div className="body__leftHeader">
          {/* Header Row 1 */}
          <div className="row1">
            <h1>To-do List</h1>

            <AddTodo />
          </div>
          {/* Header Row 2 */}
          <div className="row2">
            <div className="left">
              <DropdownSelect
                dropdown_array={sortOptions}
                value={sortOptions?.indexOf(sort_option.name)}
                value_name="Order By"
                setSelectedOption={setSortOption}
              />
            </div>

            <div className="right">
              <input
                type="text"
                placeholder="Search"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
          </div>
          <hr />
        </div>

        <div className="body__todoList">
          <TodoList
            todos={
              searchValue
                ? searchFilteredTodos
                : filter_priority_option === "All"
                ? todos
                : filtered_todos
            }
          />
        </div>
      </div>

      {/* Sidebar */}
      <div className="body__right">
        {/* Sidebar */}
        <Sidebar />
      </div>
    </div>
  );
}

export default Body;
