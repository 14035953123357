export const initialState = {
  todos: [],

  priorities: [],
  statuses: [],
  labels: [],
  sort_option: {
    name: "Date",
    asc: true,
  },
  filtered_todos: [],
  /*
   * PRIORITY OPTIONS
   * * All
   * * Normal
   * * Urgent
   */
  filter_priority_option: "All",

  user: null,
};

const sortTodos = (option, asc) => {
  return (a, b) =>
    asc
      ? a[option] < b[option]
        ? -1
        : a[option] > b[option]
        ? 1
        : 0
      : a[option] > b[option]
      ? -1
      : a[option] < b[option]
      ? 1
      : 0;
};

const reducer = (state, action) => {
  switch (action.type) {
    // FILTER BASED ON PRIORITY OPTION SELECTION
    case "FILTER_PRIORITY_OPTION":
      var filtered;
      if (action.filter_priority_option === "All") {
        filtered = [];
      } else if (state.filtered_todos.length > 0) {
        filtered = state.filtered_todos.filter(
          (todo) => todo.priority === action.filter_priority_option
        );
      } else {
        filtered = state.todos.filter(
          (todo) => todo.priority === action.filter_priority_option
        );
      }

      return {
        ...state,
        filter_priority_option: action.filter_priority_option,
        filtered_todos: filtered,
      };
    // ADD OR REMOVE USER
    case "SET_USER":
      if (action.user === null) {
        return {
          ...state,
          todos: [],
          user: action.user,
        };
      } else {
        return {
          ...state,
          user: action.user,
        };
      }

    // FETCH TODO PRIORITIES
    case "GET_TODO_PRIORITIES":
      return {
        ...state,
        priorities: action.priorities,
      };

    // FETCH TODO STATUSES
    case "GET_TODO_STATUSES":
      return {
        ...state,
        statuses: action.statuses,
      };

    // FETCH TODO LABELS
    case "GET_TODO_LABELS":
      return {
        ...state,
        labels: action.labels,
      };

    // FETCH TODO LIST
    case "GET_TODO_LIST":
      switch (state.sort_option.name) {
        //// Sort By Date
        case "Date":
          return {
            ...state,
            todos: action.todos.sort((a, b) =>
              state.sort_option.asc
                ? new Date(b.date) - new Date(a.date)
                : new Date(a.date) - new Date(b.date)
            ),
          };

        default:
          return {
            ...state,
            todos: action.todos.sort(
              sortTodos(
                state.sort_option.name.toLowerCase(),
                state.sort_option.asc
              )
            ),
          };
      }

    case "CHANGE_SORT_OPTION":
      return {
        ...state,
        sort_option: action.sort_option,
      };
    default:
      return state;
  }
};

export default reducer;
