import React from "react";
import "./Login.css";
// Components
import Logo from "../common/Logo";
// MUI
import { Button } from "@material-ui/core";
// Firebase
import { auth, googleProvider } from "../../firebase/firebase";

const Login = () => {
  const signIn = (e) => {
    auth
      .signInWithPopup(googleProvider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        console.log("USER LOGGED IN ::: ");
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        console.log("ERROR CODE :::", errorCode);
        var errorMessage = error.message;
        console.log("ERROR MESSAGE :::", errorMessage);
        alert("ERROR" + errorMessage + "\n Sign In Again");
        // The email of the user's account used.
        var email = error.email;
        console.log("ERROR EMAIL", email);
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        console.log("ERROR CREDENTIAL", credential);
        // ...
      });
  };

  return (
    <div className="login__background pattern-zigzag-sm">
      {/* <h1>Login</h1> */}
      <div className="login">
        <div className="login__logo">
          <Logo />
        </div>

        <div className="login__button">
          <Button onClick={signIn}>Sign In With Google</Button>
        </div>
      </div>
    </div>
  );
};

export default Login;
