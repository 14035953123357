import React from "react";
import "./AddTodo.css";
// Material Ui
import { Backdrop, Button, Modal } from "@material-ui/core";
// Components
import AddTodoBody from "./AddTodoBody";

function AddTodo() {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="addTodo">
      <Button onClick={handleOpen}>ADD NEW</Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="add-new-todo"
        aria-describedby="add-new-todoitem-modal"
        className="addTodo__Modal"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <AddTodoBody closeModal={handleClose} />
      </Modal>
    </div>
  );
}

export default AddTodo;
