import React, { useState } from "react";
import "./TodoItem.css";
// Material UI
import {
  CheckCircle,
  CheckCircleOutline,
  NotInterested,
} from "@material-ui/icons";
import TouchRipple from "@material-ui/core/ButtonBase";
import { Backdrop, Modal } from "@material-ui/core";
// Components
import EditTodoItem from "../EditTodoItem";
import { useStateValue } from "../../../../context/StateProvider";
import { db } from "../../../../firebase/firebase";

function TodoItem({ todoItem }) {
  const [{ user }] = useStateValue();
  const [openTodoItem, setOpenTodoItem] = useState(false);

  const handleOpenTodoItem = () => {
    setOpenTodoItem(true);
  };

  const handleCloseTodoItem = () => {
    setOpenTodoItem(false);
  };

  // Todo
  const changeStatus = (e) => {
    todoItem.status =
      todoItem.status === "Not Started"
        ? "In Progress"
        : todoItem.status === "In Progress"
        ? "Completed"
        : "Not Started";

    db.collection("users")
      .doc(user.email)
      .collection("todos")
      .doc(todoItem.id)
      .update({ status: todoItem.status });
  };

  const TodoItemDetail = () => {
    return (
      <>
        {todoItem.detail ? (
          <div onClick={handleOpenTodoItem} className="todoItem__body">
            <p className="todoItem__text">{todoItem?.detail}</p>
          </div>
        ) : undefined}
      </>
    );
  };

  //#region ::: todoItemHeader
  const TodoItemHeader = () => {
    return (
      <div className="todoItem__header">
        <div>
          <div className="todoItem__status" onClick={(e) => changeStatus(e)}>
            <TouchRipple>
              {todoItem.status === "Completed" ? (
                <CheckCircle />
              ) : todoItem.status === "In Progress" ? (
                <CheckCircleOutline />
              ) : (
                <NotInterested />
              )}
            </TouchRipple>
          </div>
          <p onClick={handleOpenTodoItem} className="todoItem__title">
            {todoItem.title}
          </p>
        </div>
        <div className="todoItem__info">
          <small className="todoItem__label">{todoItem.label}</small>
          <small className="todoItem__date">
            {new Date(todoItem.date)?.toLocaleDateString()}
          </small>
          <p onClick={handleOpenTodoItem} className="todoItem__category">
            {todoItem.priority?.toLowerCase()}
          </p>
        </div>
      </div>
    );
  };
  //#endregion

  return (
    <>
      <div
        className={
          "todoItem " +
          (todoItem.status === "Completed" ? "strike__through" : "")
        }
      >
        <TodoItemHeader />

        <TodoItemDetail />
      </div>

      <Modal
        open={openTodoItem}
        onClose={handleCloseTodoItem}
        aria-labelledby="add-new-todo"
        aria-describedby="add-new-todoitem-modal"
        className="addTodo__Modal"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <EditTodoItem closeModal={handleCloseTodoItem} todoItem={todoItem} />
      </Modal>
    </>
  );
}

export default TodoItem;
