import React, { useState } from "react";
import "./EditTodoItem.css";
// Material UI
import { Backup, Delete, HighlightOff } from "@material-ui/icons";
// Components
import DropdownSelect from "../../../common/DropdownSelect";
// Context API
import { useStateValue } from "../../../../context/StateProvider";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Button } from "@material-ui/core";
import { db } from "../../../../firebase/firebase";

function EditTodoItem({ closeModal, todoItem }, ref) {
  const [errorTitle, setErrorTitle] = useState(false);
  const [{ user, priorities, statuses, labels }] = useStateValue();
  const [formData, setFormData] = useState(todoItem);

  const changeFieldValue = (index, value) => {
    setFormData({ ...formData, [index]: value });
  };

  const updateTodoItem = () => {
    if (formData.title) {
      db.collection("users")
        .doc(user.email)
        .collection("todos")
        .doc(formData.id)
        .update(formData);
      closeModal();
    } else {
      setErrorTitle(true);
    }
  };

  const deleteTodoItem = () => {
    db.collection("users")
      .doc(user.email)
      .collection("todos")
      .doc(formData.id)
      .delete();
    closeModal();
  };

  return (
    <div ref={ref} className="editTodoItem">
      <div className="editTodoItem__header">
        <h3>Edit Todo</h3>
        <span onClick={closeModal}>
          <HighlightOff />
        </span>
      </div>

      <hr />

      {/* Form */}
      <div className="editTodoItem__form">
        <form>
          {/*  */}
          <div className="form__field">
            <h5>
              Title
              {errorTitle ? (
                <small className="error">: Set a Title before you save</small>
              ) : (
                ""
              )}
            </h5>

            <input
              onChange={(e) => changeFieldValue("title", e.target.value)}
              value={formData?.title}
              placeholder="Enter Short description of what you want Todo"
              type="text"
              required
              className={errorTitle ? "error" : ""}
              maxLength="18"
            />
          </div>

          <div className="form__field">
            <h5>Detail</h5>

            <textarea
              onChange={(e) => changeFieldValue("detail", e.target.value)}
              value={formData?.detail}
              placeholder="Optional: Enter the Detailed description of what you want Todo"
              rows="3"
              type="text"
            />
          </div>

          <div className="form__field">
            <DropdownSelect
              dropdown_array={priorities}
              value={priorities?.indexOf(formData?.priority)}
              value_name="Priority"
              setSelectedOption={(value) => changeFieldValue("priority", value)}
            />
          </div>

          <div className="form__field">
            <DropdownSelect
              dropdown_array={statuses}
              value={statuses?.indexOf(formData?.status)}
              value_name="Status"
              setSelectedOption={(value) => changeFieldValue("status", value)}
            />
          </div>

          <div className="form__field">
            <DropdownSelect
              dropdown_array={labels}
              value={labels?.indexOf(formData?.label)}
              value_name="Label"
              setSelectedOption={(value) => changeFieldValue("label", value)}
            />
          </div>

          <div className="form__field">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className="date__picker"
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                // id="date-picker-inline"
                label="Select Task Date"
                value={formData?.date}
                onChange={(date) =>
                  changeFieldValue("date", new Date(date).toLocaleString())
                }
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </div>

          <div className="form__footer">
            <Button onClick={updateTodoItem} startIcon={<Backup />}>
              Update
            </Button>
            <Button
              className="delete__button"
              onClick={deleteTodoItem}
              startIcon={<Delete />}
            >
              Delete
            </Button>
          </div>
        </form>
      </div>
      {/* {todoItem.title} */}
    </div>
  );
}

export default React.forwardRef(EditTodoItem);
