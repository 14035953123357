import React, { useState } from "react";
import "./Header.css";
// Components
import Logo from "../common/Logo";
// Icons
import { NotificationsNone } from "@material-ui/icons";
import { Avatar, Menu, MenuItem } from "@material-ui/core";
// Context API
import { useStateValue } from "../../context/StateProvider";
// Firebase
import { auth } from "../../firebase/firebase";

function Header() {
  const [{ user, todos }] = useStateValue();

  // Dropdown HTML elemnent code
  const [profileMenu, setProfileMenu] = useState(null);

  const handleShowProfileMenu = (e) => {
    setProfileMenu(e.currentTarget);
  };

  const handleCloseProfileMenu = () => {
    setProfileMenu();
  };

  const signOut = () => {
    auth.signOut();
  };

  return (
    <div className="header">
      <div className="header__container">
        <div className="header__left">
          <Logo />
        </div>

        <div className="header__right">
          <div className="header__expandwindow"></div>
          <div className="header__notification">
            <NotificationsNone fontSize="large" />
            <span className="header__notificationCount">
              {
                todos.filter((todoItem) => todoItem.status !== "Completed")
                  .length
              }
            </span>
          </div>
          <div
            className="header__userIcon"
            aria-controls="profile-menu"
            aria-haspopup="true"
            onClick={handleShowProfileMenu}
          >
            <Avatar src={user.photoURL} style={{ cursor: "pointer" }} />
          </div>
          <Menu
            id="profile-menu"
            anchorEl={profileMenu}
            keepMounted
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(profileMenu)}
            onClose={handleCloseProfileMenu}
          >
            <MenuItem onClick={signOut}>Sign Out</MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
}

export default Header;
