import React from "react";
// Components
import TodoItem from "./TodoItem";

function TodoList({ todos }) {
  return (
    <div className="todoList">
      {todos?.map((todo) => (
        <TodoItem key={todo.id} todoItem={todo} />
      ))}
    </div>
  );
}

export default TodoList;
