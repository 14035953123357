import React, { useEffect } from "react";
import "./TodoApp.css";
// Context API
import { useStateValue } from "../../context/StateProvider";
// Components
import Body from "../Body";
import Header from "../layout/Header";
// Firebase
import { db } from "../../firebase/firebase";

const TodoApp = () => {
  const [{ sort_option, user }, dispatch] = useStateValue();

  useEffect(() => {
    db.collection("users")
      .doc(user.email)
      .collection("todos")
      .onSnapshot((snapshot) =>
        dispatch({
          type: "GET_TODO_LIST",
          todos: snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })),
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort_option]);

  // useEffect(() => {
  //   db.collection("users")
  //     .doc(user.email)
  //     .collection("todos")
  //     .onSnapshot((snapshot) =>
  //       console.log(
  //         "USER TODOS ::: ",
  //         snapshot.docs.map((doc) => doc.data())
  //       )
  //     );
  // }, [user.email]);

  return (
    <>
      <Header />

      <Body />
    </>
  );
};

export default TodoApp;
