import React, { useEffect, useState } from "react";
import "./Sidebar.css";
// Icons
import {
  CheckCircle,
  CheckCircleOutline,
  NotInterested,
} from "@material-ui/icons";
// Context API
import { useStateValue } from "../../../context/StateProvider";

function Sidebar() {
  const [
    { todos, priorities, labels, statuses, filter_priority_option },
    dispatch,
  ] = useStateValue();
  const [priorityFilter, setPriorityFilter] = useState({
    Normal: true,
    Urgent: true,
  });

  useEffect(() => {
    console.log(
      "Urgent " + priorityFilter.Urgent + " Normal " + priorityFilter.Normal
    );
    var checked_option = filter_priority_option;

    if (priorityFilter.Normal === priorityFilter.Urgent) {
      checked_option = "All";
    } else if (priorityFilter.Normal) {
      checked_option = "Normal";
    } else if (priorityFilter.Urgent) {
      checked_option = "Urgent";
    }

    dispatch({
      type: "FILTER_PRIORITY_OPTION",
      filter_priority_option: checked_option,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priorityFilter]);

  return (
    <div className="sidebar">
      <div className="sidebar__section">
        <h5>Status</h5>
        <div className="sidebar__status">
          {statuses?.map((status) => (
            <p
              className={
                status === "Completed" ? "sidebar__statusCompleted" : ""
              }
              key={status}
            >
              <span>
                <span className="icon">
                  {status === "Completed" ? (
                    <CheckCircle />
                  ) : status === "In Progress" ? (
                    <CheckCircleOutline />
                  ) : (
                    <NotInterested />
                  )}
                </span>
                {status}
              </span>
              <span className="count">
                -{" "}
                {todos.filter((todoItem) => todoItem.status === status).length}
              </span>
            </p>
          ))}
        </div>
      </div>

      <div className="sidebar__section">
        <h5>Priority</h5>

        {/*  */}
        <div className="sidebar__priority">
          {priorities?.map((priority) => (
            <p key={priority}>
              <span>
                <span className="icon">
                  <input
                    defaultChecked={priorityFilter[priority]}
                    name={priority}
                    onChange={(e) =>
                      setPriorityFilter({
                        ...priorityFilter,
                        [priority]: e.target.checked,
                      })
                    }
                    type="checkbox"
                  />
                </span>
                {priority}
              </span>
              <span className="count">
                -
                {
                  todos.filter((todoItem) => todoItem.priority === priority)
                    .length
                }
              </span>
            </p>
          ))}
        </div>
      </div>

      {/*  */}
      <div className="sidebar__section">
        <h5>Labels</h5>
        <div className="sidebar__labels">
          {labels.map((label) => (
            <span key={label} className="sidebar__label">
              {label}
            </span>
          ))}
        </div>
      </div>
      {/*  */}
    </div>
  );
}

export default Sidebar;
