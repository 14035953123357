import React from "react";
import "./Logo.css";
// MUI
import { LowPriority } from "@material-ui/icons";

const Logo = () => {
  return (
    <div className="logo">
      <LowPriority className="logoIcon" fontSize="large" />
      <span>Todo App</span>
    </div>
  );
};

export default Logo;
