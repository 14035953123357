import React, { useState } from "react";
import "./AddTodoBody.css";

// Date Picker Util
import DateFnsUtils from "@date-io/date-fns";
// Material Ui
import { Backup, HighlightOff } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
// Firebase
import { db } from "../../../firebase/firebase";
// Context API
import { useStateValue } from "../../../context/StateProvider";
// Components
import DropdownSelect from "../../common/DropdownSelect";

const defaultTodo = {
  title: "",
  detail: "",
  priority: "Normal",
  status: "Not Started",
  label: "Personal",
  date: new Date().toLocaleString(),
};

function AddTodoBody({ closeModal }, ref) {
  const [errorTitle, setErrorTitle] = useState(false);
  const [{ user, priorities, statuses, labels }] = useStateValue();
  const [todoFormData, setTodoFormData] = useState(defaultTodo);

  const changeFieldValue = (index, value) => {
    setTodoFormData({ ...todoFormData, [index]: value });
  };

  const saveTodoItem = (e) => {
    e.preventDefault();

    if (todoFormData.title) {
      db.collection("users")
        .doc(user.email)
        .collection("todos")
        .add(todoFormData);
      closeModal();
    } else {
      setErrorTitle(true);
    }
  };

  return (
    <div ref={ref} className="addTodoBody">
      <div className="addTodoBody__header">
        <h3>Add New Todo</h3>
        <span onClick={closeModal}>
          <HighlightOff />
        </span>
      </div>
      <hr />

      {/* Form */}
      <div className="addTodoBody__form">
        <form>
          {/*  */}

          {/* Title */}
          <div className="form__field">
            <h5>
              Title
              {errorTitle ? (
                <small className="error">: Set a Title before you save</small>
              ) : (
                ""
              )}
            </h5>

            <input
              onChange={(e) => changeFieldValue("title", e.target.value)}
              value={todoFormData.title}
              placeholder="Enter Short description of what you want Todo"
              type="text"
              // required
              className={errorTitle ? "error" : ""}
              maxLength="18"
            />
          </div>

          {/* Detail */}
          <div className="form__field">
            <h5>Detail</h5>

            <textarea
              onChange={(e) => changeFieldValue("detail", e.target.value)}
              value={todoFormData.detail}
              placeholder="Optional: Enter the Detailed description of what you want Todo"
              rows="3"
              type="text"
            />
          </div>

          {/* Priority */}
          <div className="form__field">
            <DropdownSelect
              dropdown_array={priorities}
              value={priorities?.indexOf(todoFormData.priority)}
              value_name="Priority"
              setSelectedOption={(value) => changeFieldValue("priority", value)}
            />
          </div>

          {/* Status */}
          <div className="form__field">
            <DropdownSelect
              dropdown_array={statuses}
              value={statuses?.indexOf(todoFormData.status)}
              value_name="Status"
              setSelectedOption={(value) => changeFieldValue("status", value)}
            />
          </div>

          {/* Label */}
          <div className="form__field">
            <DropdownSelect
              dropdown_array={labels}
              value={labels?.indexOf(todoFormData.label)}
              value_name="Label"
              setSelectedOption={(value) => changeFieldValue("label", value)}
            />
          </div>

          {/* Date */}
          <div className="form__field">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className="date__picker"
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                // id="date-picker-inline"
                label="Select Task Date"
                value={todoFormData.date}
                onChange={(value) =>
                  changeFieldValue("date", new Date(value).toLocaleString())
                }
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </div>

          <Button onClick={saveTodoItem} startIcon={<Backup />}>
            Save
          </Button>
        </form>
      </div>
    </div>
  );
}

export default React.forwardRef(AddTodoBody);
