import firebase from "firebase";
require("firebase/auth");

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyBJ1FT_HU8By5rpzy8J1bskacBSi50Uv5A",
  authDomain: "todo.jesuseyitan.com",
  // authDomain: "http://localhost:3000/",
  databaseURL: "todo-app-3a1f4.firebaseio.com",
  projectId: "todo-app-3a1f4",
  storageBucket: "todo-app-3a1f4.appspot.com",
  messagingSenderId: "396697242960",
  appId: "1:396697242960:web:32771d2860609806067055",
  measurementId: "G-LPPBSR9BER",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const auth = firebase.auth();
const googleProvider = new firebase.auth.GoogleAuthProvider();

export { db, auth, googleProvider };
