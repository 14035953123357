import React, { useEffect } from "react";
import "./App.css";
// Theme
import "./components/theme/theme.css";
// Component
import TodoApp from "./components/TodoApp";
import Login from "./components/Login/Login";
// Context API
import { useStateValue } from "./context/StateProvider";
// Firebase
import { auth, db } from "./firebase/firebase";

function App() {
  const [{ user }, dispatch] = useStateValue();

  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        // Add user Data to context api if user exists after authStatechanged
        dispatch({
          type: "SET_USER",
          user: authUser,
        });
      } else {
        // Remove user Data from context api if user doesnt exists after authStatechanged
        dispatch({
          type: "SET_USER",
          user: null,
        });
      }
    });

    // Get Priority Statuses and Labels from firestore
    db.collection("priority").onSnapshot((snapshot) =>
      dispatch({
        type: "GET_TODO_PRIORITIES",
        priorities: snapshot.docs.map((doc) => doc.data().name),
      })
    );
    db.collection("status").onSnapshot((snapshot) =>
      dispatch({
        type: "GET_TODO_STATUSES",
        statuses: snapshot.docs.map((doc) => doc.data().name),
      })
    );
    db.collection("label").onSnapshot((snapshot) =>
      dispatch({
        type: "GET_TODO_LABELS",
        labels: snapshot.docs.map((doc) => doc.data().name),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      const userDbDoc = db.collection("users").doc(user?.email);
      // Creation of Todo Collection for user if it doest exist already
      userDbDoc.get().then((userSnapshot) => {
        if (!userSnapshot.exists) {
          userDbDoc.set({}).then(() =>
            userDbDoc.collection("todos").doc("01").set({
              title: "Your First Todo",
              detail: "Click here to Edit The Todo",
              date: new Date().toLocaleString(),
              id: "01",
              label: "Personal",
              priority: "Normal",
              status: "Not Started",
            })
          );
        }
      });
    }
  }, [user]);

  return user ? (
    <div className="app">
      <TodoApp />
    </div>
  ) : (
    <Login />
  );
}

export default App;
